
































import { Component, Vue } from 'vue-property-decorator';
import CouponUsed from '@/pages/P0918/CouponUsed.vue';
import AboutTicket from '@/pages/P0918/AboutTicket.vue';
import App from '@/pages/P0918/App.vue';

@Component({
  components: { CouponUsed, AboutTicket, App }
})
export default class P0919 extends Vue {}
